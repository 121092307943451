<template>
  <div class="home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <!-- <headerCont v-if="headerContState" /> -->
    <router-view v-if="!loginModal.show" />
    <div class="seat_box" v-if="loginModal.show"></div>
    <foot-menu />
  </div>
</template>
<script>
import headerMenu from "@/components/pc/headerIndex.vue";
import footMenu from "@/components/pc/footerIndex.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      headerContState: false,
      logoUrl: "",
      deepLogoUrl: "",
    };
  },
  computed: {
    ...mapState(["loginModal", "userInfo"]),
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow, headerContState } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        this.headerContState = headerContState;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.CloseLoginModal();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";

    if (!this.getCookie("AccessKeySecret")) {
      this.getTokenInfo();
    }
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "CloseLoginModal"]), //登录弹窗
    getWebsiteInfo() {},

    getTokenInfo() {
      this.$axios.post(`/index/upload_sts_auth`).then((res) => {
        console.log(res, "res");
        let starTime = new Date().getTime();
        let endTime = new Date(res.Expiration).getTime();
        // console.log((endTime-starTime)/1000,'stera')
        let overTime = Math.trunc((endTime - starTime) / 1000);
        console.log(overTime,'overTime')
        // sessionStorage.setItem('AccessKeySecret',JSON.stringify(res))
        document.cookie =
          "accessKeyId=" + res.accessKeyId + "; max-age=" +overTime ;
        document.cookie =
          "AccessKeySecret=" +
          res.AccessKeySecret +
          "; max-age=" +
          overTime ;
        document.cookie =
          "SecurityToken=" + res.SecurityToken + "; max-age=" + overTime ;
      });
    },
    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.seat_box {
  // height: 820px;
  background: #f0f5f9;
}
</style>
