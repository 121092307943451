<template>
  <div class="header_cont">
    <div class="cont_text w_1200">
        <p class="tips">{{tips}}</p>
        <p class="title">{{title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "header_cont",
  data() {
    return {
      
    };
  },
  props: {
    menu: Array,
    title: {
      type: String,
      default: "大会介绍",
    },
    tips:{
        type: String,
        default: "",
    }
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.header_cont {
  height: 400px;
  background: url('@/assets/cont_bgm.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -168px;
  overflow: hidden;
 
  .cont_text{
    margin-top: 230px;
    p.title{
        font-size: 38px;
        color: #FFFFFF;
        line-height: 53px;
    }
    p.tips{
        font-size: 26px;
        color: #FFFFFF;
        line-height: 38px;
    }
  }
}
</style>
