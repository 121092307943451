<template>
  <div class="home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <!-- <headerCont v-if="headerContState" /> -->
    <router-view v-if="!mLoginModal.show" />
    <div class="seat_box" v-if="mLoginModal.show"></div>
    <foot-menu v-if="!mLoginModal.show && headerState"  />
  </div>
</template>
<script>
import headerMenu from "@/components/m/headerIndex.vue";
import footMenu from "@/components/m/footerIndex.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "m-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      headerContState:false,
      logoUrl: '',
      deepLogoUrl:'',
    };
  },
   computed: {
    ...mapState([ "mLoginModal", "userInfo"]),
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow,headerContState } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        this.headerContState = headerContState ;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.CloseMLoginModal()
      },
      immediate: true,
    },
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";
  },
  methods: {
     ...mapMutations([
      "ShowMLoginModal",
      "CloseMLoginModal"
    ]), //登录弹窗
    getWebsiteInfo() {
      
    },
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.seat_box{
  // height: 820px;
  background: #F0F5F9;
}

</style>
