<template>
  <div class="header_box">
    <div class="head_bgm">
      <div class="logo_login_box w_1200 flex">
        <p class="logo">
          <img src="@/assets/logo.png" alt="" />
        </p>
        <div class="login">
          <div class="user_info_box flex" v-if="userInfo">
            <p class="user_name">欢迎_{{ userInfo.username }}</p>
            <p
              class="get_out btn lower cursor"
              v-if="lowerBtn"
              @click="$router.push('/subordinateAccount')"
            >
              下级账号
            </p>
            <p class="get_out btn cursor" @click="getOutLogin()">退出</p>
            <p class="get_out btn lower cursor" @click="modifyBtn()">
              修改密码
            </p>
          </div>
          <p v-else class="btn cursor" @click="loginBtn()">登录</p>
        </div>
      </div>
      <div class="menu_list">
        <ul class="flex w_1200">
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/index"
              >首页</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/sessionIntro"
              >大会介绍</router-link
            >
          </li>
          <!-- <li @click="menuBtn()"><router-link active-class="activeMenu" to="/newsDetail?id=14&type=notice">通知公告</router-link></li> -->
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/noticeList"
              >通知公告</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/newsCenter"
              >新闻动态</router-link
            >
            <!-- 新闻动态 -->
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/showImg/list"
              >风采展示</router-link
            >
            
          </li>
          <li @click="menuBtn()">
            参展申报
            <div class="sub-menu-list">
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare1List"
                >大国工匠展陈信息</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare2List"
                >全国示范性创新工作室展陈信息</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare3List"
                >全国职工优秀技术创新成果展陈信息</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare4List"
                >全国巾帼工匠成果展陈信息</router-link
              >
              <!-- <router-link class="sub-menu" active-class="activeMenu" to="/declare5List">全总展区展品申报</router-link> -->
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare6List"
                >参展单位布展方案申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare7List"
                >工匠大会分论坛需求申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare8List"
                >各全国产业工会展陈信息</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare9List"
              >
                各省（区、市）总工会、各全国产业工会展陈信息</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare10List"
                >各省（区、市）总工会、各全国产业工会展区展品申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare11List"
                >展区亮点申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare12List"
                >路演项目申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare13List"
                >直播内容申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare14List"
                >知识产权保护承诺书申报</router-link
              >
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare15List"
                >参展材料已脱密承诺书申报</router-link
              >
              
              <!-- <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare16List"
                >大会报名</router-link
              > -->
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare17List"
                >参展视频及图片报送</router-link
              >
            </div>
          </li>
          <li @click="menuBtn()">
            <!-- <router-link active-class="activeMenu" to="/exhibitingReport">观众申报</router-link> -->
            大会报名
            <div class="sub-menu-list">
              <router-link
                class="sub-menu"
                active-class="activeMenu"
                to="/declare16List"
                >参会人员申报</router-link
              >
              <div class="sec_item sub-menu" @click="gzyyBtn()">观众预约</div>
            </div>
          </li>
          <!-- <li @click="menuBtn('1')">
            <router-link active-class="activeMenu" to="/boothReport"
              >展位申报</router-link
            >
          </li>
          <li @click="menuBtn('1')">
            <router-link active-class="activeMenu" to="/activityReport"
              >活动申报</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/exhibitingReport"
              >参展申报</router-link
            >
          </li> -->
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/aboutUs"
              >联系我们</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="login_box">
      <Login v-if="loginModal.show" />
    </div>

    <!-- 观众预约申报弹框 -->
    <div class="mask_cont_box" v-if="maskState">
      <div class="cont_code_box">
        <div class="error_text" v-if="errorState">
          <p class="text">{{ errorText }}</p>
        </div>
        <div class="close_img" @click="maskState = false">
          <img src="@/assets/del-2-ico.png" alt="" />
        </div>
        <p class="title">观众预约申报</p>
        <div class="form_cont_box">
          <div class="tab_cont">
            <p
              class="btn1"
              :class="showIndex == 1 ? 'active' : ''"
              @click="changeTab('1')"
            >
              预约通道
            </p>
            <p
              class="btn2"
              :class="showIndex == 2 ? 'active' : ''"
              @click="changeTab('2')"
            >
              找回二维码
            </p>
          </div>
          <div class="form_data" v-if="!codeImgState">
            <ul>
              <p v-if="showIndex == 1" class="tips_text">地点：北京展览馆</p>
              <li class="select_cont" >
                <el-select
                  v-model="formData.date"
                  @change="selectBtn()"
                  placeholder="请选择预约时间段"
                >
                  <el-option
                    v-for="item in numLimitList"
                    :key="item.id"
                    :label="item.date"
                    :value="item.date"
                    >{{ item.date }}</el-option
                  >
                </el-select>
              </li>
              <p class="tips_text_cont"  v-if="showIndex == 1">
                备注:当天16:30停止检票入场
                <span v-if="formData.date">本时段预约名额已满</span>
              </p>
              <li>
                <p class="text"></p>
                <p class="input">
                  <input
                    type="text"
                    v-model="formData.name"
                    placeholder="姓名"
                  />
                </p>
              </li>
              <li>
                <p class="text"></p>
                <p class="input">
                  <input
                    type="text"
                    v-model="formData.phone"
                    placeholder="手机号"
                  />
                </p>
              </li>
              <li>
                <p class="text"></p>
                <p class="input">
                  <input
                    type="text"
                    v-model="formData.id_card"
                    placeholder="身份证号"
                  />
                </p>
              </li>
              <template v-if="showIndex == 1">
                <!-- <li>
                  <p class="text"></p>
                  <p class="input">
                    <input
                      type="text"
                      v-model="formData.unit"
                      placeholder="单位"
                    />
                  </p>
                </li>
                <li>
                  <p class="text"></p>
                  <p class="input">
                    <input
                      type="text"
                      v-model="formData.post"
                      placeholder="职务"
                    />
                  </p>
                </li>-->
                <li class="input_list">
                  <div class="input_item">
                    <p class="input_name">
                      <input
                        type="text"
                        v-model="peers[0].name"
                        placeholder="同行人姓名"
                      />
                    </p>
                    <p class="input_idcard">
                      <input
                        type="text"
                        v-model="peers[0].idcard"
                        placeholder="同行人身份证号"
                      />
                    </p>
                  </div>
                  <div class="input_item input_item1">
                    <p class="input_name">
                      <input
                        type="text"
                        v-model="peers[1].name"
                        placeholder="同行人姓名"
                      />
                    </p>
                    <p class="input_idcard">
                      <input
                        type="text"
                        v-model="peers[1].idcard"
                        placeholder="同行人身份证号"
                      />
                    </p>
                  </div>
                  <p class="text">备注：同行人限两人</p>
                </li>
              </template>
            </ul>
            <div
              v-if="showIndex == 1"
              :class="
                formData.date &&
                formData.name &&
                formData.phone &&
                formData.id_card
                  ? 'btn active'
                  : 'btn'
              "
              @click="submitBtn()"
            >
              预约
            </div>
            <div
              v-if="showIndex == 2"
              :class="
                formData.name && formData.phone && formData.id_card
                  ? 'btn active'
                  : 'btn'
              "
              @click="submitBtn()"
            >
              预约
            </div>
            <!-- <div class="btn active" @click="submitBtn()">预约</div> -->
          </div>
          <div class="code_img_cont" v-if="codeImgState">
            <p class="img">
              <img :src="codeImgUrl" alt="" />
            </p>
            <p class="text_tips">
              您的预约二维码如下，请<span>截图或拍照</span>保存此二维码作为入场凭证
            </p>
          </div>
        </div>
        <p class="tips_text" v-if="showIndex == 1 && !codeImgState">
          提示：提交预约后将生成二维码，请截图或拍照保存，二维码作为进场观展的凭证，请勿遗失。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "@/components/pc/login.vue";
import { mapState, mapMutations } from "vuex";
import { viewerAip } from "@/utils/util";
export default {
  name: "headerBox",
  data() {
    return {
      userInfo: "",
      lowerBtn: false,
      errorText: "身份证号已存在，请勿重复预约",
      showIndex: 1,
      formData: {},
      codeImgState: false, //是否显示二维码
      codeImgUrl: "", //二维码链接
      errorState: false, //错误信息显示状态
      maskState: false, //弹出框显示状态
      numLimitList: [],
      numValue: 999,
      peers: [
        {
          name: "",
          idcard: "",
        },
        {
          name: "",
          idcard: "",
        },
      ],
    };
  },
  components: {
    Login,
  },
  computed: {
    ...mapState(["loginModal"]),
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.userInfo = sessionStorage.getItem("gjUserData")
      ? JSON.parse(sessionStorage.getItem("gjUserData"))
      : "";
    this.lowerBtn =
      sessionStorage.getItem("userLevel") &&
      sessionStorage.getItem("userLevel") == 2
        ? true
        : false;
    this.getDateList();
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "CloseLoginModal"]), //登录弹窗
    // 点击登录
    loginBtn() {
      this.ShowLoginModal();
    },

    // 退出登录
    getOutLogin() {
      sessionStorage.clear();
      location.reload(); //刷新页面
    },

    modifyBtn() {
      this.$router.push("/changePass");
    },

    menuBtn(num) {
      if (num) {
      } else {
        if (this.loginModal.show) {
          this.CloseLoginModal();
        }
      }
      // if(this.loginModal.show){}
    },

    // tab切换
    changeTab(num) {
      this.codeImgState = false;
      this.showIndex = num;
      for (let i in this.formData) {
        this.formData[i] = "";
      }
    },
    // 观众预约
    gzyyBtn() {
      // let timeValue = new Date().getTime();
      // let starTime = new Date("2023-07-19 00:00:00").getTime();
      // let endTime = new Date("2023-07-27 17:00:00").getTime();
      // if (starTime > timeValue) {
      //   this.$message.error("活动时间未开始");
      // }
      // if (starTime < timeValue && timeValue < endTime) {
        this.maskState = true;
        this.getDateList();
      // }
      // if (timeValue > endTime) {
      //   this.$message.error("报名时间已结束");
      // }
    },
    getDateList() {
      this.$axios
        .post(`${viewerAip}/scan/reservice/number_limit`)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.numLimitList = res.data;
          }
        });
    },
    selectBtn() {
      console.log(this.formData.date, "formData.date");
      this.numLimitList.forEach((el) => {
        if (el.date == this.formData.date) {
          this.numValue = el.remaining;
        }
      });
    },
    // 预约
    submitBtn() {
      this.errorState= true
      this.errorText='观众预约已满'
      return

      let fomrCont = new FormData();
      fomrCont.append("name", this.formData.name);
      fomrCont.append("phone", this.formData.phone);
      fomrCont.append("id_card", this.formData.id_card);
      fomrCont.append("date", this.formData.date);
      if (this.showIndex == 1) {
        // fomrCont.append("unit", this.formData.unit);
        // fomrCont.append("post", this.formData.post);
        // fomrCont.append("remarks", this.formData.remarks);
      
        this.formData.remarks = "";
        if (this.peers[0].name) {
          if (!this.peers[0].idcard) {
            this.errorState = true;
            this.errorText = "请填写同行人身份证号";
            return;
          }
        }
        if (this.peers[1].name) {
          if (!this.peers[1].idcard) {
            this.errorState = true;
            this.errorText = "请填写同行人身份证号";
            return;
          }
        }
        for (let i = 0; i < this.peers.length; i++) {
          console.log(this.peers[i].name, "this.peers[i].name");
          if (this.peers[i].name) {
            this.formData.remarks +=
              this.peers[i].name + "，" + this.peers[i].idcard + "。";
          }
        }
        fomrCont.append("remarks", this.formData.remarks);
        console.log(this.formData.remarks);
        if (
          this.formData.date &&
          this.formData.name &&
          this.formData.phone &&
          this.formData.id_card
        ) {
          // 首次预约
          this.$axios
            .post(`${viewerAip}/scan/reservice/reservice`, fomrCont)
            .then((res) => {
              if (res.code == 1) {
                this.codeImgUrl = res.data;
                this.codeImgState = true;
                this.getDateList();
              } else {
                this.errorState = true;
                this.errorText = res.msg;
                setTimeout(() => {
                  this.errorState = false;
                  this.errorText = "";
                }, 2000);
              }
            });
        }
      }
      if (this.showIndex == 2) {
        // 找回二维码
        if (
          this.formData.name &&
          this.formData.phone &&
          this.formData.id_card
        ) {
          this.$axios
            .post(`${viewerAip}/scan/reservice/selectEwm`, fomrCont)
            .then((res) => {
              if (res.code == 1) {
                this.codeImgUrl = res.data;
                this.codeImgState = true;
              } else {
                this.errorState = true;
                this.errorText = res.msg;
                setTimeout(() => {
                  this.errorState = false;
                  this.errorText = "";
                }, 2000);
              }
            });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header_box {
  width: 100%;
  box-sizing: border-box;
  // height: 168px;
  position: relative;
  z-index: 999;
  .head_bgm {
    background: rgba(51, 51, 51, 0.2);
    position: relative;
    z-index: 99;
  }
  .login_box {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  // logo&登录
  .logo_login_box {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 29px 0;
    p.logo {
      img {
        width: auto;
        height: 47.84px;
      }
    }
    .login {
      p.btn {
        width: 59px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #05229f;
        font-size: 14px;
        &.lower {
          width: auto;
          padding: 0 10px;
        }
      }

      // 退出登录
      .user_info_box {
        p {
          color: #fff;
          font-size: 16px;
        }
        p.get_out {
          margin-left: 15px;
          color: #05229f;
        }
      }
    }
  }

  // 导航栏
  .menu_list {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    ul {
      justify-content: center;
      li {
        // margin-right: 80px;
        height: 60px;
        line-height: 60px;
        position: relative;
        margin: 0 30px;
      }
      li,
      a {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: block;
        height: 100%;
        cursor: pointer;
      }
      li:hover,
      .activeMenu,
      li a:hover {
        color: #afdbf9;
      }
      li:hover::after {
        content: "";
        width: 41px;
        height: 2px;
        background: #afdbf9;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      .activeMenu::after {
        content: "";
        width: 41px;
        height: 2px;
        background: #afdbf9;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .sub-menu-list {
      width: 300px;
      padding: 10px 0;
      background: rgba(51, 51, 51, 0.8);
      position: absolute;
      top: 60px;
      left: -110px;
      margin: 0 auto;
      display: none;
    }
    .sub-menu {
      display: block;
      padding: 10px 0;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      line-height: 1;
      text-align: center;
    }
    li:hover .sub-menu-list {
      display: block;
    }
  }
}

// 观众申报预约弹框
.mask_cont_box {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
  .cont_code_box {
    height: 85vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 588px;
    background: #ebeeff;
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    div.error_text {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -35px;
      z-index: 99;
      width: 333px;
      height: 44px;
      background: #ff3030;
      line-height: 44px;
      text-align: center;
      color: #fff;
    }
    div.close_img {
      display: flex;
      justify-content: flex-end;
      margin: 30px 30px 0 0;
      position: relative;
      z-index: 9;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    p.title {
      text-align: center;
      font-size: 38px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 550;
      color: #05229f;
      margin-top: -15px;
    }
    .form_cont_box {
      background: #fff;
      margin: 38px 47px 14px;
      flex: 1;
      overflow-y: auto;
      border-radius: 14px;
      padding-bottom: 32px;
      .tab_cont {
        display: flex;
        p {
          flex: 1;
          text-align: center;
          height: 70px;
          background: #f4f5f6;
          line-height: 70px;
          font-size: 24px;
          color: #999999;
          position: relative;
          cursor: pointer;
        }
        p.btn1 {
          border-radius: 12px 0px 0px 0px;
        }
        p.btn2 {
          border-radius: 0px 12px 0px 0px;
        }
        p.active {
          background: #fff;
          color: #000;
        }
        p.active::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 8px;
          transform: translateX(-50%);
          width: 48px;
          height: 2px;
          background: #333333;
        }
      }
      .form_data {
        margin: 24px 32px 0;
        p.tips_text{
          color: #05229f;
          text-align: left;
          font-size: 16px;
          margin-bottom: 20px;
        }
        li {
          margin-bottom: 20px;
          height: 50px;
          background: #f7f7f8;
          border-radius: 8px;
          display: flex;
          align-items: center;
          width: 100%;
          display: flex;
          p.input {
            flex: 1;
            margin: 0 19px;
            input {
              width: 100%;
              font-size: 16px;
              background: none;
            }
          }
        }
        li.select_cont {
          display: block;
          .el-select {
            width: 100%;
            height: 100%;
            border: none;
          }
          ::v-deep .el-input__inner {
            border: none;
            background: none;
            height: 50px;
          }
          p.text {
          }
        }
        .tips_text_cont {
          color: #05229f;
          font-size: 16px;
          margin-top: -15px;
          margin-bottom: 10px;
          span {
            color: #ff3030;
          }
        }
        li.input_list {
          height: 140px;
          display: block;
          background: none;
          .input_item {
            display: flex;
            margin-bottom: 20px;
            p {
              height: 50px;
              background: #f7f7f8;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              input {
                width: 85%;
                height: 90%;
                background: none;
              }
            }
            p.input_name {
              width: 128px;
            }
            p.input_idcard {
              flex: 1;
              margin-left: 12px;
            }
          }
          .input_item1 {
            margin-bottom: 0;
          }
          p.text {
            margin-top: 5px;
            color: #05229f;
            font-size: 16px;
          }
        }
        div.btn {
          height: 50px;
          background: #05229f;
          border-radius: 8px;
          font-size: 16px;
          color: #ffffff;
          opacity: 0.15;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
        }
        div.btn.active {
          opacity: 1;
        }
      }
    }
    .tips_text {
      text-align: center;
      font-size: 12px;
      color: #05229f;
    }

    // 二维码
    .code_img_cont {
      p.img {
        width: 228px;
        height: 228px;
        margin: 94px auto 38px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p.text_tips {
        margin: 50px;
        color: #333;
        font-size: 16px;
        text-align: center;
        span {
          color: #4d73ff;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  margin-left: 14px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #05229f;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:active {
  background: #05229f;
  border-radius: 10px;
}


::v-deep .el-input input::-webkit-input-placeholder {
    color: #999999;
    // font-size: 16px;
}

::v-deep .el-input input::-moz-input-placeholder {
    color: #999999;
    // font-size: 16px;
}

::v-deep .el-input input::-ms-input-placeholder {
    color: #999999;
    // font-size: 16px;
}
</style>
