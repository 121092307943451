<template>
  <div class="container_main">
    <headerCont :title="'账号登录'" :tips="''" />
    <div class="login_cont_box ">
        <!-- <div class="left_img">
            <img src="@/assets/logo_img.png" alt="">
        </div> -->
        <div class="login_cont_input">
            <div class="form_input">
                <ul>
                    <li>
                        <p class="img_icon"><img src="@/assets/login/name.png" alt=""></p>
                        <p class="input"><input type="text" placeholder="请输入账号" v-model="loginForm.account"></p>
                    </li>
                    <li>
                        <p class="img_icon"><img src="@/assets/login/pass.png" alt=""></p>
                        <p class="input"><input type="password" placeholder="请输入密码" v-model="loginForm.password"></p>
                    </li>
                </ul>
            </div>
            <div class="submit_btn cursor" @click="submitBtn()">登录</div>
            <p class="tips">
                参展报名咨询：（010）68591845 <br>
                <!-- 综合服务咨询：(010)62005996 -->
            </p>
        </div>
    </div>
  </div>
</template>
<script>
import headerCont from "@/components/m/headCont.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "header_login",
  components: {
    headerCont,
  },
  data() {
    return {
      loginForm:{
        account:'',
        password:''
      }
    };
  },
 
  mounted() {
  
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "CloseLoginModal"]), //登录弹窗

    submitBtn(){
        if(!this.loginForm.account){
            this.$message.error('请输入账号')
            return
        }
        if(!this.loginForm.password){
            this.$message.error('请输入密码')
            return
        }
        let arry = new FormData()
        Object.keys(this.loginForm).map((key) => {
            arry.append(key, this.loginForm[key]);
        });
        this.$axios.post(`/index/login`,arry).then(res=>{
            console.log(res)
            if(res.code==1){
                this.$message.success('登录成功')
                this.CloseLoginModal()
                sessionStorage.setItem('gjUserData',JSON.stringify(res.data.userinfo))
                 location.reload(); //刷新页面
            }else{
                this.$message.error(res.msg)
            }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.container_main{
    padding-bottom: 120px;
    background: #fff;
    .login_cont_box{
        margin: 80px 32px 0;
        background: #fff;
        display: flex;
        border-radius: 10px;
        .left_img{
            width: 550px;
            height: 330px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .login_cont_input{
            box-sizing: border-box;
            flex: 1;
            h2{
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #05229F;
                padding-top: 30px;
                text-align: center;
                margin: 0;
            }
            .form_input{
                margin-top: 22px;
                li{
                    margin-bottom: 16px;
                    height: 92px;
                    background: #F7F7F7;
                    border-radius: 44px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 23px;
                    p.img_icon{
                        margin-right: 10px;
                    }
                    p.input{
                        flex: 1;
                        input{
                            font-size: 36px;
                            background: none;
                        }
                    }
                }
            }
            .submit_btn{
                margin: 80px auto 0;
                height: 92px;
                background: #05229F;
                border-radius: 45px;;
                background: #05229F;
                text-align: center;
                line-height: 92px;
                font-size: 32px;
                color: #FFFFFF;
            }
            p.tips{
                text-align: center;
                margin-top: 32px;
                font-size: 24px;
                color: #999999;
            }

        }

    }
}
::-webkit-input-placeholder{/*Webkit browsers*/
	color:#999;
	font-size:32px;
}
:-moz-placeholder{/*Mozilla Firefox 4 to 8*/
	color:#999;
	font-size:32px;
}
::moz-placeholder{/*Mozilla Firefox 19+*/
	color:#999;
	font-size:32px;
}
:-ms-input-placeholder{/*Internet Explorer 10+*/
    color:#999;
    font-size:32px;
}

</style>
