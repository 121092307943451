export const ApiUrl = 'https://2023gjdhapi.kjcxchina.com/api/v2';
export const viewerAip = 'https://2023gjdhapi.kjcxchina.com/api';


import Vue from 'vue'
import { put } from "@/utils/Oss";
import md5 from "blueimp-md5";


//判断客户端
export const IsPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];

    let flag = true;

    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break
        }
    }

    return flag
};
// 验证手机号
export const IsRegular = (data) => {
    return /^1[3456789]\d{9}$/.test(data)
}
// 验证身份证号
export const VerificationCard = (data) => {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data)
}
//判断是否为微信浏览器
export const IsWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') != -1;

    if (isWeixin) {
        return true;
    } else {
        return false;
    }
}

//手机号/邮箱脱敏
export const Desensitization = (data) => {
    if (data.includes('@')) {
        let email = data.split('@');
        if (email[0].length > 4) {
            return email[0].substring(0, email[0].length - 3) + '*****' + email[1];
        } else {
            return '*****' + email[1];
        }
    } else {
        return data.slice(0, 3) + "****" + data.slice(data.length - 4);
    }
}

//上传文件
const upload = (item, type, _this, timeNum) => {
    if(_this.$refs[type] instanceof Array){
        _this.$refs[type][0].clearFiles();
    }else{
        _this.$refs[type].clearFiles();
    }
    
    var timestamp = Date.now();
    var fileName = item.file ? item.file.name : item.name; // 当前本地上传的这张图片的名称(没有时间日期)
    var name = fileName.slice(0, fileName.lastIndexOf('.'));
    var pic = fileName.slice(fileName.lastIndexOf('.') + 1);
    // var name = fileName.split(".")[0];
    // var pic = fileName.split(".")[1];
    var filePath = '/dggj/' + md5(name) + '.' + timestamp + '.' + pic;
    var file = item.file ? item.file : item; // 当前本地上传的这张图片
    var uploadVideoState = true
    var timeSet = '' //计时器
    if (type.includes('_image')) {
        if (pic != 'jpg' && pic != 'JPG' && pic != 'JPEG' && pic != 'jpeg') {
            return _this.$message.error("请上传格式为jpg的图片");
        }
        if(type=='show_images'){
            const isLt2M = file.size / 1024 / 1024 > 5;
            if (isLt2M) {
                _this.$message.error("图片大小不得大于5M");
                return
            }
        }else{
            console.log(pic, 'pic')
            
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (isLt2M) {
                _this.$message.error("图片大小不得小于3M");
                return
            }
        }
        
    }
    console.log(type,'type')
    if (type.includes('_file')) {
        if (type == 'show_files' || type == 'logo_files' || type == 'word_files' || type=='excel_file' || type=='ppt_files'||type=='pdf_file') {
            if(type== 'word_files' || type=='excel_file'){
                if (pic != 'xls' && pic !='xlsx' ) {
                    return _this.$message.error("请上传格式为excel的文件");
                }
            }else if(type=='ppt_files' ){
                if (pic != 'ppt' && pic != 'pptx') {
                    return _this.$message.error("请上传格式为ppt的文件");
                }
            }else if(type=='pdf_file'){
                if (pic != 'pdf' ) {
                    return _this.$message.error("请上传格式为pdf的文件");
                }
            }else if(type != 'logo_files'){
                //word、excel、pdf
                if (pic != 'doc' && pic !='docx' && pic != 'excel' && pic != 'pdf') {
                    return _this.$message.error("请上传格式为word、excel、pdf的文件");
                }
            }
            
           
            put(filePath, file).then((result) => {
                console.log(result, 'result', filePath)
                // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
                if (!_this.uplods[type] || _this.uplods[type].length == 0) {
                    Vue.set(_this.uplods, type, []);
                }
                // if (pic != 'jpg' && pic != 'JPG' && pic != 'JPEG' && pic != 'jpeg') {
                result.url += '|' + name + '.' + pic;
                // }
                _this.uplods[type].push(result.url);
            });
        // } else if (type == 'logo_files') {
        //     // if (pic != 'ai') {
        //     //     return _this.$message.error("请上传格式为ai的文件");
        //     // }
        //     put(filePath, file).then((result) => {
        //         console.log(result, 'result', filePath)
        //         // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
        //         if (!_this.uplods[type] || _this.uplods[type].length == 0) {
        //             Vue.set(_this.uplods, type, []);
        //         }
        //         // if (pic != 'jpg' && pic != 'JPG' && pic != 'JPEG' && pic != 'jpeg') {
        //         result.url += '|' + name + '.' + pic;
        //         // }
        //         _this.uplods[type].push(result.url);
        //     });
        } else {
            // if (pic != 'mp4') {
            //     return _this.$message.error("请上传格式为mp4的视频");
            // }
            // 视频大小及时长
            // new Promise((resolve) => {
            //     let videoElement = document.createElement("video");
            //     videoElement.src = URL.createObjectURL(file);
            //     videoElement.addEventListener("loadedmetadata", function () {
            //         // console.log(videoElement.duration,'ppp') //视频时长
            //         // console.log(Math.ceil(file.size / 1024 / 1024,'size')) //视频大小
            //         videoTime = 100

            //     });
            // });
            let videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);
            videoElement.addEventListener("loadedmetadata", function (e) {
                // console.log(videoElement.duration,'ppp') //视频时长
                // console.log(Math.ceil(file.size / 1024 / 1024,'size')) //视频大小
                uploadVideoState = false
                if (Math.ceil(videoElement.duration) > timeNum) {
                    _this.$confirm('本视频上传建议时长' + timeNum + '秒，您本次上传视频时长' + Math.ceil(videoElement.duration) + '秒，是否继续上传？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let arryCont = [0.2, 0.5, 0.8, 0.3]
                        if (type == 'desc_file') {
                            _this.loadProgress1 = 0
                            _this.loadProgress1State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress1 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress1 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }
                        if (type == 'achievement_file') {
                            _this.loadProgress2 = 0
                            _this.loadProgress2State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress2 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress2 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }
                        if (type == 'work_file') {
                            _this.loadProgress3 = 0
                            _this.loadProgress3State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress3 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress3 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }
                        if (type == 'video_files') {
                            _this.loadProgress13 = 0
                            _this.loadProgress13State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress13 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress13 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }

                        put(filePath, file).then((result) => {
                            if (!result) {
                                if (type == 'desc_file') {
                                    _this.loadProgress1 = 0
                                    _this.loadProgress1State = false
                                }
                                if (type == 'achievement_file') {
                                    _this.loadProgress2 = 0
                                    _this.loadProgress2State = false

                                }
                                if (type == 'work_file') {
                                    _this.loadProgress3 = 0
                                    _this.loadProgress3State = false
                                }
                                if (type == 'video_files') {
                                    _this.loadProgress13 = 0
                                    _this.loadProgress13State = false
                                }
                                _this.$message.error('视频过大或网络不稳定，视频上传失败，请重试')
                                return

                            }
                            // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
                            if (!_this.uplods[type] || _this.uplods[type].length == 0) {
                                Vue.set(_this.uplods, type, []);
                            }
                            if (pic != 'jpg') {
                                result.url += '|' + name + '.' + pic;
                            }
                            clearInterval(timeSet)
                            if (type == 'desc_file') {
                                _this.loadProgress1 = 100
                                setTimeout(() => {
                                    _this.loadProgress1State = false
                                }, 500)
                            }
                            if (type == 'achievement_file') {
                                _this.loadProgress2 = 100
                                setTimeout(() => {
                                    _this.loadProgress2State = false
                                }, 500)
                            }
                            if (type == 'work_file') {
                                _this.loadProgress3 = 100
                                setTimeout(() => {
                                    _this.loadProgress3State = false
                                }, 500)
                            }
                            if (type == 'video_files') {
                                _this.loadProgress13 = 100
                                setTimeout(() => {
                                    _this.loadProgress13State = false
                                }, 500)
                            }
                            _this.uplods[type].push(result.url);
                        });
                    }).catch(() => {

                        return

                    });
                } else {
                    let arryCont = [0.2, 0.5, 0.8, 0.3]
                        if (type == 'desc_file') {
                            _this.loadProgress1 = 0
                            _this.loadProgress1State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress1 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress1 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }
                        if (type == 'achievement_file') {
                            _this.loadProgress2 = 0
                            _this.loadProgress2State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress2 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress2 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }
                        if (type == 'work_file') {
                            _this.loadProgress3 = 0
                            _this.loadProgress3State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress3 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress3 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }
                        if (type == 'video_files') {
                            _this.loadProgress13 = 0
                            _this.loadProgress13State = true
                            timeSet = setInterval(() => {
                                _this.loadProgress13 += Math.floor(Math.random() * arryCont.length);
                                if (_this.loadProgress13 >= 95) {
                                    clearInterval(timeSet)
                                }
                            }, 1000)
                        }

                    put(filePath, file).then((result) => {
                        // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
                        if (!_this.uplods[type] || _this.uplods[type].length == 0) {
                            Vue.set(_this.uplods, type, []);
                        }
                        if (pic != 'jpg') {
                            result.url += '|' + name + '.' + pic;
                        }
                        clearInterval(timeSet)
                        if (type == 'desc_file') {
                            _this.loadProgress1 = 100
                            setTimeout(() => {
                                _this.loadProgress1State = false
                            }, 500)
                        }
                        if (type == 'achievement_file') {
                            _this.loadProgress2 = 100
                            setTimeout(() => {
                                _this.loadProgress2State = false
                            }, 500)
                        }
                        if (type == 'work_file') {
                            _this.loadProgress3 = 100
                            setTimeout(() => {
                                _this.loadProgress3State = false
                            }, 500)
                        }
                        if (type == 'video_files') {
                            _this.loadProgress13 = 100
                            setTimeout(() => {
                                _this.loadProgress13State = false
                            }, 500)
                        }
                        _this.uplods[type].push(result.url);
                    });
                }

            });



        }
    } else {
        put(filePath, file).then((result) => {
            console.log(result, 'result', filePath)
            // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
            if (!_this.uplods[type] || _this.uplods[type].length == 0) {
                Vue.set(_this.uplods, type, []);
            }
            // if (pic != 'jpg' && pic != 'JPG' && pic != 'JPEG' && pic != 'jpeg') {
            result.url += '|' + name + '.' + pic;
            // }
            _this.uplods[type].push(result.url);
        });
    }

}




Vue.prototype.$upload = upload;

import { Message } from "element-ui";
//删除上传
const uplodDel = (type, index, showType, uplods) => {
    if (showType != 1) {
        uplods[type].splice(index, 1);
        console.log(uplods,'uplods')
    }
}
Vue.prototype.$uplodDel = uplodDel;

// 播放视频
const showVideo = (url, _this)=>{
    _this.videoUrl=url
    _this.videoState = true;
}
// 数据暂存
const saveDataStorage = (type,content,uplods)=>{
    console.log(uplods,'uplods')
    for(let i in uplods){
        Vue.set(content, i, uplods[i].join())
      }
    localStorage.setItem(type,JSON.stringify(content))
    Message.success("暂存成功");
}
Vue.prototype.$showVideo = showVideo;
Vue.prototype.$saveDataStorage = saveDataStorage;