<template>
  <div class="footer">
    <div class="footer-top">
      <img class="logo" src="@/assets/logo.png">
      <div class="line"></div>
      <div class="content">
        <p>主办单位：<span>中华全国总工会</span><span>中共北京市委</span><span>北京市人民政府</span></p>
        <p>承办单位：<span>中华全国总工会劳动和经济工作部</span><span>北京市总工会</span></p>
      </div>
    </div>
    <!-- <div class="footer-bottom">Copyright © 2015-2023 kjcxchina.com All Rights Reserved | 京ICP备16012224号</div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataInfo: "",
    };
  },
  props: {
    deepLogoUrl: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.dataInfo = JSON.parse(localStorage.getItem("siteInfo"));
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.footer{
  background: url('@/assets/footer_bgm.png') no-repeat 0 0/cover
}
.footer-top{
  padding: 82px 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo{
    width: auto;
    height: 48px;
  }
  .line{
    width: 1px;
    height: 54px;
    background-color: #fff;
    margin: 0 32px 0 28px;
  }
  .content{
    font-size: 16px;
    color: #FFF;
    line-height: 1.5;
    span{
      margin-right: 10px;
    }
  }
}
.footer-bottom{
  padding: 24px 0 36px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
}
</style>
