<template>
  <div class="header_cont">
    <div class="cont_text ">
        <p class="title">{{title}}</p>
        <p class="tips">{{tips}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "header_cont",
  data() {
    return {
      
    };
  },
  props: {
    menu: Array,
    title: {
      type: String,
      default: "大会介绍",
    },
    tips:{
        type: String,
        default: "",
    }
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.header_cont {
  height: 460px;
  background: url('@/assets/m/cont_bgm.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -168px;
  overflow: hidden;
 
  .cont_text{
    margin: 240px 32px 0;
    p.title{
        font-size: 48px;
        color: #FFFFFF;
    }
    p.tips{
        font-size: 28px;
        color: #FFFFFF;
        margin-top: 12px;
    }
  }
}
</style>
