<template>
  <div class="header_box">
    <div class="head_bgm">
      <div class="logo_login_box flex">
        <p class="logo">
          <img src="@/assets/m/logo.png" alt="" />
        </p>
        <div class="login">
          <div class="user_info_box flex" v-if="userInfo">
            <p class="get_out btn cursor" @click="getOutLogin()">退出</p>
          </div>
          <p v-else class="btn cursor" @click="loginBtn()">登录</p>
        </div>
        <div class="menu_icon" id="moreBox">
          <img
            v-if="!menuState"
            @click="menuState = !menuState"
            src="@/assets/m/menu_icon.png"
            alt=""
          />
          <img
            v-if="menuState"
            @click="menuState = !menuState"
            src="@/assets/m/menu_close.png"
            alt=""
          />
        </div>
      </div>
      <div class="menu_list" v-if="menuState">
        <ul>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/index"
              >首页</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/sessionIntro"
              >大会介绍</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/newsCenter"
              >新闻动态</router-link
            >
          </li>
          <li @click="menuBtn('1')">
            <router-link active-class="activeMenu" to="/boothReport"
              >展位申报</router-link
            >
          </li>
          <li @click="menuBtn('1')">
            <router-link active-class="activeMenu" to="/activityReport"
              >活动申报</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/exhibitingReport"
              >参展申报</router-link
            >
          </li>
          <li @click="menuBtn()">
            <router-link active-class="activeMenu" to="/aboutUs"
              >联系我们</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="login_box">
      <Login v-if="mLoginModal.show" />
    </div>
  </div>
</template>

<script>
import Login from "@/components/m/login.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "headerBox",
  data() {
    return {
      userInfo: "",
      menuState: false,
    };
  },
  components: {
    Login,
  },
  computed: {
    ...mapState(["mLoginModal"]),
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.userInfo = sessionStorage.getItem("gjUserData")
      ? JSON.parse(sessionStorage.getItem("gjUserData"))
      : "";
    document.addEventListener("click", (e) => {
      let box = document.getElementById("moreBox");
      if (!box.contains(e.target)) {
         this.menuState = false
      }
      // console.log(this.menuState,'this.menuState')
      // if(this.menuState){
      //   this.menuState = false
      // }
    });
  },
  methods: {
    ...mapMutations(["ShowMLoginModal", "CloseMLoginModal"]), //登录弹窗

    // 点击登录
    loginBtn() {
      this.ShowMLoginModal();
    },

    // 退出登录
    getOutLogin() {
      sessionStorage.clear();
      location.reload(); //刷新页面
    },

    menuBtn(num) {
      console.log(this.mLoginModal.show, "this.mLoginModal.show");
      this.menuState = false;
      if (num) {
      } else {
        if (this.mLoginModal.show) {
          this.CloseMLoginModal();
        }
      }
      // if(this.loginModal.show){}
    },
  },
};
</script>

<style scoped lang="scss">
.header_box {
  width: 100%;
  box-sizing: border-box;
  height: 132px;
  position: relative;
  z-index: 99;
  .head_bgm {
    background: rgba(51, 51, 51, 0.2);
    position: relative;
    z-index: 99;
    height: 132px;
  }
  .login_box {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  // logo&登录
  .logo_login_box {
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 0 32px;
    p.logo {
      img {
        width: auto;
        height: 58.65px;
      }
    }
    .menu_icon{
      display: none;
      img{
        display: block;
      }
    }
    .login {
      margin-left: auto;
      margin-right: 54px;
      display: none;
      p.btn {
        width: 120px;
        height: 64px;
        border-radius: 8px;
        text-align: center;
        line-height: 64px;
        color: #fff;
        font-size: 28px;
        background: #3d8cff;
      }
      // 退出登录
      .user_info_box {
        p {
          color: #fff;
          font-size: 16px;
        }
        p.get_out {
          margin-left: 15px;
        }
      }
    }
  }

  // 导航栏
  .menu_list {
    position: absolute;
    top: 132px;
    right: 0;
    width: 584px;
    background: rgba(0, 0, 0, 0.85);
    ul {
      justify-content: space-between;
      li {
        // margin-right: 80px;

        height: 114px;
        line-height: 114px;
        position: relative;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        a {
          font-size: 36px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: block;
          height: 100%;
        }
      }
      // li:hover::after {
      //   content: "";
      //   width: 41px;
      //   height: 2px;
      //   background: #9adee0;
      //   position: absolute;
      //   left: 50%;
      //   bottom: 0;
      //   transform: translateX(-50%);
      // }
      // .activeMenu::after {
      //   content: "";
      //   width: 41px;
      //   height: 2px;
      //   background: #9adee0;
      //   position: absolute;
      //   left: 50%;
      //   bottom: 0;
      //   transform: translateX(-50%);
      // }
      li:last-child {
        margin-right: 0;
        border: none;
      }
    }
  }
}
</style>
