<template>
  <div class="container_main">
    <headerCont :title="'账号登录'" :tips="''" />
    <div class="login_cont_box w_1200">
      <div class="left_img">
        <img src="@/assets/logo_img.png" alt="" />
      </div>
      <div class="login_cont_input">
        <h2>登录</h2>
        <div class="form_input">
          <ul>
            <li>
              <p class="img_icon">
                <img src="@/assets/login/name.png" alt="" />
              </p>
              <p class="input">
                <input
                  type="text"
                  placeholder="请输入账号"
                  v-model="loginForm.account"
                />
              </p>
            </li>
            <li>
              <p class="img_icon">
                <img src="@/assets/login/pass.png" alt="" />
              </p>
              <p class="input">
                <input
                  type="password"
                  placeholder="请输入密码"
                  v-model="loginForm.password"
                />
              </p>
            </li>
          </ul>
        </div>
        <div class="submit_btn cursor" @click="submitBtn()">登录</div>
        <p class="tips">
          参展报名咨询：（010）68591845 <br />
          <!-- 综合服务咨询：(010)62005996 -->
        </p>
      </div>
    </div>
    <div class="supplement" v-if="supplementLayer">
      <div class="content">
        <div class="close" @click="supplementClose">
          <i class="el-icon-close"></i>
        </div>
        <div class="content-title">完善信息</div>
        <el-form
          ref="form"
          :model="form"
          :rules="formRules"
          label-width="127px"
          class="demo-form-inline"
          :inline="true"
          size="medium"
        >
          <el-form-item label="联系人名称:" prop="contact_name">
            <el-input
              v-model="form.contact_name"
              placeholder="请输入联系人名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人部门:" prop="contact_department">
            <el-input
              v-model="form.contact_department"
              placeholder="请输入联系人部门"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人职务:" prop="contact_job_title">
            <el-input
              v-model="form.contact_job_title"
              placeholder="请输入联系人职务"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人电话:" prop="contact_phone">
            <el-input
              v-model="form.contact_phone"
              maxlength="11"
              type="number"
              placeholder="请输入联系人电话"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="content_btn" @click="supplementBtn('form')">保存</div>
      </div>
    </div>
  </div>
</template>
<script>
import headerCont from "@/components/pc/headCont.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "header_login",
  components: {
    headerCont,
  },
  data() {
    return {
      loginForm: {
        account: "",
        password: "",
      },
      supplementLayer: false,
      form: {},
      formRules: {
        contact_name: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        contact_department: [
          { required: true, message: "请输入联系人部门", trigger: "blur" },
        ],
        contact_job_title: [
          { required: true, message: "请输入联系人职务", trigger: "blur" },
        ],
        contact_phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},
  methods: {
    ...mapMutations(["ShowLoginModal", "CloseLoginModal"]), //登录弹窗

    submitBtn() {
      if (!this.loginForm.account) {
        this.$message.error("请输入账号");
        return;
      }
      if (!this.loginForm.password) {
        this.$message.error("请输入密码");
        return;
      }
      let arry = new FormData();
      Object.keys(this.loginForm).map((key) => {
        arry.append(key, this.loginForm[key]);
      });
      this.$axios.post(`/index/login`, arry).then((res) => {
        if (res.code == 1) {
            sessionStorage.setItem( "gjUserData", JSON.stringify(res.data.userinfo));
            sessionStorage.setItem( "userLevel", JSON.stringify(res.data.level));
          if ( !res.data.userinfo.contact_name || res.data.userinfo.contact_name == "" ) {
            this.supplementLayer = true;
          } else {
            this.$message.success("登录成功");
            console.log('ppp')
            if(res.data.level && res.data.level==3 && window.location.href.includes('subordinateAccount')){
                this.$router.replace("/");    
                this.CloseLoginModal();
                 setTimeout(()=>{
                    location.reload(); //刷新页面
                  },30)
            }else{
                 location.reload(); //刷新页面
                this.CloseLoginModal();
            }
            
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //补充信息提交
    supplementBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(`/index/userinfo`, this.form, { useLog: true })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success("提交成功");
                this.CloseLoginModal();
                location.reload(); //刷新页面
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          return this.$message.error("请填写必填项");
        }
      });
    },
    //关闭补充
    supplementClose(){
        this.supplementLayer = false;
        this.form = {};
        sessionStorage.clear();
    }
  },
};
</script>

<style lang="scss" scoped>
.container_main {
  padding-bottom: 120px;
  background: #f0f5f9;
  .login_cont_box {
    width: 1100px;
    margin-top: 120px;
    background: #fff;
    display: flex;
    height: 330px;
    border-radius: 10px;
    .left_img {
      width: 550px;
      height: 330px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .login_cont_input {
      box-sizing: border-box;
      flex: 1;
      h2 {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #05229f;
        padding-top: 30px;
        text-align: center;
        margin: 0;
      }
      .form_input {
        margin-left: 125px;
        margin-top: 22px;
        li {
          margin-bottom: 16px;
          width: 300px;
          height: 44px;
          background: #f7f7f7;
          border-radius: 4px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0 23px;
          p.img_icon {
            margin-right: 10px;
          }
          p.input {
            flex: 1;
            input {
              font-size: 14px;
              background: none;
            }
          }
        }
      }
      .submit_btn {
        margin: 24px auto 0;
        width: 300px;
        height: 45px;
        background: #05229f;
        border-radius: 4px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        color: #ffffff;
      }
      p.tips {
        text-align: center;
        margin-top: 17px;
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
.supplement {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    box-sizing: border-box;
    width: 556px;
    background: #fff;
    border-radius: 6px;
    position: relative;
    padding: 60px 42px;
  }
  .content-title {
    font-size: 38px;
    color: #4d73ff;
    margin-bottom: 30px;
  }
  ::v-deep .el-input {
    width: 332px;
  }
  .content_btn {
    width: 300px;
    height: 45px;
    background: #4d73ff;
    border-radius: 4px;
    font-size: 20px;
    color: #ffffff;
    line-height: 45px;
    text-align: center;
    margin: 40px auto 0;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
