
import Axios from 'axios'
let OSS = require('ali-oss');
let client = ''
if (!getCookie('AccessKeySecret')) {
  Axios.post(`https://2023gjdhapi.kjcxchina.com/api/v2/index/upload_sts_auth`).then(el => {
    console.log(el)
    let res = el.data
    let starTime = new Date().getTime();
    let endTime = new Date(res.Expiration).getTime();
    // console.log((endTime-starTime)/1000,'stera')
    let overTime = Math.trunc((endTime - starTime) / 1000);
    console.log(overTime, 'overTime')
    // sessionStorage.setItem('AccessKeySecret',JSON.stringify(res))
    document.cookie =
      "accessKeyId=" + res.accessKeyId + "; max-age=" + overTime;
    document.cookie =
      "AccessKeySecret=" +
      res.AccessKeySecret +
      "; max-age=" +
      overTime;
    document.cookie =
      "SecurityToken=" + res.SecurityToken + "; max-age=" + overTime;


    client = new OSS({
      endpoint: 'https://oss-cn-beijing.aliyuncs.com', //bucket所在的区域， 默认oss-cn-hangzhou。
      accessKeyId: getCookie('accessKeyId'),  //通过阿里云控制台创建的AccessKey。
      accessKeySecret: getCookie('AccessKeySecret'),  //通过阿里云控制台创建的AccessSecret。
      bucket: 'new-jnqg',  //通过控制台或PutBucket创建的bucket。
      stsToken: getCookie('SecurityToken'),
    });

  })
} else {
  client = new OSS({
    endpoint: 'https://oss-cn-beijing.aliyuncs.com', //bucket所在的区域， 默认oss-cn-hangzhou。
    accessKeyId: getCookie('accessKeyId'),  //通过阿里云控制台创建的AccessKey。
    accessKeySecret: getCookie('AccessKeySecret'),  //通过阿里云控制台创建的AccessSecret。
    bucket: 'new-jnqg',  //通过控制台或PutBucket创建的bucket。
    stsToken: getCookie('SecurityToken'),
  });
}

// let client = new OSS({
//   region: 'oss-cn-beijing', //bucket所在的区域， 默认oss-cn-hangzhou。
//   accessKeyId: 'LTAI5tJe2kdYYxor9xmu2Unm',  //通过阿里云控制台创建的AccessKey。
//   accessKeySecret: 'JIVUO97blE63VGem0L0sVoCA2X3QCy',  //通过阿里云控制台创建的AccessSecret。
//   bucket: 'new-jnqg',  //通过控制台或PutBucket创建的bucket。
//   exposeHeaders: ['ETag','x-oss-request-id','x-oss-version-id']
// });

// 上传
export async function put(filePath, file) {
  try {
    let result = await client.put(filePath, file)
    return result
  } catch (err) {
  }
}

// 删除
export async function remove(filePath) {
  try {
    let result = await client.delete(filePath)
    return result
  } catch (err) {
  }
}

// 分段上传
export async function initiateMultipartUpload(filePath, file) {

  var file = file
  var fileSize = file.size;
  var chunkSize = 20 * 1024 * 1024; // 每个分片的大小（这里设置为 5MB）
  // var uploadId; // 分片上传的 ID
  // var uploadList; // 分片上传的列表
  try {
    const result = await client.multipartUpload(filePath, file, {
      // parallel: 4, // 并发上传的分片数量
      partSize: chunkSize
    });
    console.log(result, 'result')
    return 'http://new-jnqg.oss-cn-beijing.aliyuncs.com' + result.name


    console.log('分片上传成功：', result);
  } catch (error) {
    console.error('分片上传失败：', error);
  }
}
function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}