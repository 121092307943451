<template>
  <div class="footer">
    <img class="logo" src="@/assets/m/logo.png">
    <div class="text">主办单位：<br><span>中华全国总工会</span><span>中共北京市委</span><span>北京市人民政府</span></div>
    <div class="text">承办单位：<br><span>中华全国总工会劳动和经济工作部</span><span>北京市总工会</span></div>
    <!-- <div class="footer_cont">Copyright © 2015-2023 kjcxchina.com All Rights Reserved | 京ICP备16012224号</div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataInfo: "",
    };
  },
  props: {
    deepLogoUrl: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.dataInfo = JSON.parse(localStorage.getItem("siteInfo"));
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.footer{
    padding: 80px 0;
    background: url('@/assets/m/deep_bgm.png') no-repeat;
    text-align: center;
    font-size: 28px;
    color: #fff;
    .logo{
      width: 300px;
      margin-bottom: 40px;
    }
    .text{
      font-size: 28px;
      line-height: 1.5;
      margin-bottom: 20px;
      span{
        margin-right: 10px;
      }
    }
    .footer_cont{
      padding-top: 38px;
      border-top: 2px solid rgba(255, 255, 255, 0.4);
      font-size: 24px;
      color: rgba(255, 255, 255, 0.4);
    }
}
</style>
