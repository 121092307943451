import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        meta: {
        }
      },
      // 大会介绍
      {
        path: '/sessionIntro',
        name: 'sessionIntro',
        component: () => import('@/views/pc/sessionIntro.vue'),
        meta: {
        }
      },
      // 联系我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/pc/aboutUs.vue'),
        meta: {
        }
      },
      // 新闻动态
      {
        path: '/newsCenter',
        name: 'newsCenter',
        component: () => import('@/views/pc/newsCenter.vue'),
        meta: {
        }
      },
      // 新闻动态
      {
        path: '/noticeList',
        name: 'noticeList',
        component: () => import('@/views/pc/noticeList.vue'),
        meta: {
        }
      },
      // 新闻详情
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/pc/newsDetail.vue'),
        meta: {
        }
      },
      // 登录
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: () => import('@/views/pc/login.vue'),
      //   meta: {
      //   }
      // },
      // 展位申报
      {
        path: '/boothReport',
        name: 'boothReport',
        component: () => import('@/views/pc/boothReport.vue'),
        meta: {
        }
      },
      // 展位申报-报名
      {
        path: '/boothEnroll',
        name: 'boothEnroll',
        component: () => import('@/views/pc/boothEnroll.vue'),
        meta: {
        }
      },
      // 活动申报
      {
        path: '/activityReport',
        name: 'activityReport',
        component: () => import('@/views/pc/activityReport.vue'),
        meta: {
        }
      },
      // 活动申报-报名
      {
        path: '/activityEnroll',
        name: 'activityEnroll',
        component: () => import('@/views/pc/activityEnroll.vue'),
        meta: {
        }
      },
      // 参展申报
      {
        path: '/exhibitingReport',
        name: 'exhibitingReport',
        component: () => import('@/views/pc/exhibitingReport.vue'),
        meta: {
        }
      },
      // 大国工匠展陈信息列表
      {
        path: '/declare1List',
        name: 'Declare1List',
        component: () => import('@/views/pc/declare1List.vue'),
        meta: {
        }
      },
      // 大国工匠展陈信息
      {
        path: '/declare1Declare',
        name: 'Declare1Declare',
        component: () => import('@/views/pc/declare1Declare.vue'),
        meta: {
        }
      },
      // 全国示范性创新工作室展陈信息
      {
        path: '/declare2List',
        name: 'Declare2List',
        component: () => import('@/views/pc/declare2List.vue'),
        meta: {
        }
      },
      // 全国示范性创新工作室展陈信息
      {
        path: '/declare2Declare',
        name: 'Declare2Declare',
        component: () => import('@/views/pc/declare2Declare.vue'),
        meta: {
        }
      },
      // 全国职工优秀技术创新成果展陈信息
      {
        path: '/declare3List',
        name: 'Declare3List',
        component: () => import('@/views/pc/declare3List.vue'),
        meta: {
        }
      },
      // 全国职工优秀技术创新成果展陈信息
      {
        path: '/declare3Declare',
        name: 'Declare3Declare',
        component: () => import('@/views/pc/declare3Declare.vue'),
        meta: {
        }
      },
      // 全国巾帼工匠成果展陈信息
      {
        path: '/declare4List',
        name: 'Declare4List',
        component: () => import('@/views/pc/declare4List.vue'),
        meta: {
        }
      },
      // 全国巾帼工匠成果展陈信息
      {
        path: '/declare4Declare',
        name: 'Declare4Declare',
        component: () => import('@/views/pc/declare4Declare.vue'),
        meta: {
        }
      },
      // 全总展区展品申报
      {
        path: '/declare5List',
        name: 'Declare5List',
        component: () => import('@/views/pc/declare5List.vue'),
        meta: {
        }
      },
      // 全总展区展品申报
      {
        path: '/declare5Declare',
        name: 'Declare5Declare',
        component: () => import('@/views/pc/declare5Declare.vue'),
        meta: {
        }
      },
      // 全总展区展品申报
      {
        path: '/declare6List',
        name: 'Declare6List',
        component: () => import('@/views/pc/declare6List.vue'),
        meta: {
        }
      },
      // 全总展区展品申报
      {
        path: '/declare6Declare',
        name: 'Declare6Declare',
        component: () => import('@/views/pc/declare6Declare.vue'),
        meta: {
        }
      },
      // 全总展区展品申报
      {
        path: '/declare7List',
        name: 'Declare7List',
        component: () => import('@/views/pc/declare7List.vue'),
        meta: {
        }
      },
      // 全总展区展品申报
      {
        path: '/declare7Declare',
        name: 'Declare7Declare',
        component: () => import('@/views/pc/declare7Declare.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare8List',
        name: 'Declare8List',
        component: () => import('@/views/pc/declare8List.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare8Declare',
        name: 'Declare8Declare',
        component: () => import('@/views/pc/declare8Declare.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare9List',
        name: 'Declare9List',
        component: () => import('@/views/pc/declare9List.vue'),
        meta: {
        }
      },
      {
        path: '/declare9Declare',
        name: 'Declare9Declare',
        component: () => import('@/views/pc/declare9Declare.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare10List',
        name: 'Declare10List',
        component: () => import('@/views/pc/declare10List.vue'),
        meta: {
        }
      },
      {
        path: '/declare10Declare',
        name: 'Declare10Declare',
        component: () => import('@/views/pc/declare10Declare.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare11List',
        name: 'Declare11List',
        component: () => import('@/views/pc/declare11List.vue'),
        meta: {
        }
      },
      {
        path: '/declare11Declare',
        name: 'Declare11Declare',
        component: () => import('@/views/pc/declare11Declare.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare12List',
        name: 'Declare12List',
        component: () => import('@/views/pc/declare12List.vue'),
        meta: {
        }
      },
      {
        path: '/declare12Declare',
        name: 'Declare12Declare',
        component: () => import('@/views/pc/declare12Declare.vue'),
        meta: {
        }
      },
      // 全总产业工会展陈信息
      {
        path: '/declare13List',
        name: 'Declare13List',
        component: () => import('@/views/pc/declare13List.vue'),
        meta: {
        }
      },
      {
        path: '/declare13Declare',
        name: 'Declare13Declare',
        component: () => import('@/views/pc/declare13Declare.vue'),
        meta: {
        }
      },
      {
        path: '/declare14List',
        name: 'Declare14List',
        component: () => import('@/views/pc/declare14List.vue'),
        meta: {
        }
      },
      {
        path: '/declare14Declare',
        name: 'Declare14Declare',
        component: () => import('@/views/pc/declare14Declare.vue'),
        meta: {
        }
      },
      {
        path: '/declare15List',
        name: 'Declare15List',
        component: () => import('@/views/pc/declare15List.vue'),
        meta: {
        }
      },
      {
        path: '/declare15Declare',
        name: 'Declare15Declare',
        component: () => import('@/views/pc/declare15Declare.vue'),
        meta: {
        }
      },
      {
        path: '/declare16List',
        name: 'Declare16List',
        component: () => import('@/views/pc/declare16List.vue'),
        meta: {
        }
      },
      {
        path: '/declare17Declare',
        name: 'Declare17Declare',
        component: () => import('@/views/pc/declare17Declare.vue'),
        meta: {
        }
      },
      {
        path: '/declare17List',
        name: 'Declare17List',
        component: () => import('@/views/pc/declare17List.vue'),
        meta: {
        }
      },
      {
        path: '/declare17Declare',
        name: 'Declare17Declare',
        component: () => import('@/views/pc/declare17Declare.vue'),
        meta: {
        }
      },
      // 下级账号
      {
        path: '/subordinateAccount',
        name: 'SubordinateAccount',
        component: () => import('@/views/pc/subordinateAccount.vue'),
        meta: {
        }
      },
      // 修改密码
      {
        path: '/changePass',
        name: 'changePass',
        component: () => import('@/views/pc/changePass.vue'),
        meta: {
        }
      },
      // 风采展示-列表
      {
        path: '/showImg/list',
        name: 'showImg/list',
        component: () => import('@/views/pc/showImg/list.vue'),
        meta: {
        }
      },
      //  风采展示-详情
      {
        path: '/showImg/detail',
        name: 'showImg/detail',
        component: () => import('@/views/pc/showImg/detail.vue'),
        meta: {
        }
      },
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/m/index.vue'),

      },
      {
        path: '/viewer/login',
        name: 'viewer/login',
        component: () => import('@/views/m/viewer/login.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true
        }
      },
      {
        path: '/viewer/formIndex',
        name: 'viewer/formIndex',
        component: () => import('@/views/m/viewer/formIndex.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true
        }
      },
      {
        path: '/viewer/form',
        name: 'viewer/form',
        component: () => import('@/views/m/viewer/form.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true
        }
      },
      {
        path: '/viewer/index',
        name: 'viewer/index',
        component: () => import('@/views/m/viewer/index.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true
        }
      },
      {
        path: '/viewer/scan',
        name: 'viewer/scan',
        component: () => import('@/views/m/viewer/scan.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true
        }
      }, {
        path: '/viewer/recordList',
        name: 'viewer/recordList',
        component: () => import('@/views/m/viewer/recordList.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true
        }
      },
      // 大会介绍
      {
        path: '/sessionIntro',
        name: 'sessionIntro',
        component: () => import('@/views/m/sessionIntro.vue'),
        meta: {
        }
      },
      // 联系我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/m/aboutUs.vue'),
        meta: {
        }
      },
      // 新闻动态
      {
        path: '/newsCenter',
        name: 'newsCenter',
        component: () => import('@/views/m/newsCenter.vue'),
        meta: {
        }
      },
      // 新闻详情
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/m/newsDetail.vue'),
        meta: {
        }
      },
      
      // 登录
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: () => import('@/views/m/login.vue'),
      //   meta: {
      //   }
      // },
      // 展位申报
      {
        path: '/boothReport',
        name: 'boothReport',
        component: () => import('@/views/m/boothReport.vue'),
        meta: {
        }
      },
      // 展位申报-报名
      {
        path: '/boothEnroll',
        name: 'boothEnroll',
        component: () => import('@/views/m/boothEnroll.vue'),
        meta: {
        }
      },
      // 活动申报
      {
        path: '/activityReport',
        name: 'activityReport',
        component: () => import('@/views/m/activityReport.vue'),
        meta: {
        }
      },
      // 活动申报-报名
      {
        path: '/activityEnroll',
        name: 'activityEnroll',
        component: () => import('@/views/m/activityEnroll.vue'),
        meta: {
        }
      },
      // 参展申报
      {
        path: '/exhibitingReport',
        name: 'exhibitingReport',
        component: () => import('@/views/m/exhibitingReport.vue'),
        meta: {
        }
      },
      // 风采展示-列表
      {
        path: '/showImg/list',
        name: 'showImg/list',
        component: () => import('@/views/m/showImg/list.vue'),
        meta: {
        }
      },
      //  风采展示-详情
      {
        path: '/showImg/detail',
        name: 'showImg/detail',
        component: () => import('@/views/m/showImg/detail.vue'),
        meta: {
        }
      },
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
})

export default router
